<template>
<div class="ny_pad">
	<div class="menu_ico">
	 <top_menu></top_menu>
	 <!-- ho_menu -->
	 </div>
	 <!-- menu_ico news-->
	 <div class="about_lay " id="xw1">
	    <div class="about_wapper">



<!--	     <p class="piclay"><img src="@/assets/images/icon27.png" /></p>-->
<!--	     <dl class="fontlay dj_lay">-->
<!--	      <ul id="myTab1" class="riqi_tab">-->
<!--	       <li class="active" href="#taocan1" data-toggle="tab">2022 01/11</li>-->
<!--	       <li href="#taocan2" data-toggle="tab">2022 01/10</li>-->
<!--	       <li href="#taocan3" data-toggle="tab">2022 01/09</li>-->
<!--	      </ul>-->
<!--	      <dd id="myTabContent1">-->
<!--	       <div class="tab-pane fade active in" id="taocan1">-->
<!--	        <router-link :to="{path:'/content',query:{id:item.id}}" v-for="(item, index) in testdata" :key="index">-->
<!--	         <strong>{{(item.title||'').slice(0,17)}}</strong>-->
<!--	         {{(item.description||'').slice(0,30)}}-->
<!--	        </router-link>-->
<!--	       </div>-->
<!--	       <div class="tab-pane fade" id="taocan2">-->
<!--	        <router-link :to="{path:'/content',query:{id:item.id}}" v-for="(item, index) in testdata1" :key="index">-->
<!--	         <strong>{{(item.title||'').slice(0,17)}}</strong>-->
<!--	         {{(item.description||'').slice(0,30)}}-->
<!--	        </router-link>-->
<!--	       </div>-->
<!--	       <div class="tab-pane fade" id="taocan3">-->
<!--	        <router-link :to="{path:'/content',query:{id:item.id}}" v-for="(item, index) in testdata2" :key="index">-->
<!--	         <strong>{{(item.title||'').slice(0,17)}}</strong>-->
<!--	         {{(item.description||'').slice(0,30)}}-->
<!--	        </router-link>-->
<!--	       </div>-->
<!--	      </dd>-->
<!--	     </dl>-->
	     <!-- dj_lay -->
	    </div>
	    <!-- about_wapper -->
	  </div>
	  <!-- about_lay -->

  <!--        <h1>公司新闻</h1>-->
  <div class="container" id="xw2">
    <div class="row pd10"><img src="@/assets/images/xw.gif" /></div>
    <div class="row blog-left-content" v-for="(item, index) in testdata" :key="index">

      <div class="blog-left-img col-lg-2 col-md-12 col-sm-12">
        <a :href="'/content?id='+item.id">
          <img :src="item.images" :alt="item.title">
        </a>
      </div>

      <div class="content col-lg-8 col-md-12 col-sm-12">
        <H3><a :href="'/content?id='+item.id">{{(item.title||'')}}</a></H3>
        <!--                <p>{{(item.description||'')}}</p>-->
<!--        <a :href="'/content?'+item.id" class="read-btn">Read More</a>-->
      </div>

      <div class="blog-right col-lg-2 col-md-12 col-sm-12">
        {{(item.publishTime||'').slice(0,11).replace(/-/g,'/')}}
        <!--                {{(item.publishTime||'').slice(5,11).replaceAll('-','/')}}-->
      </div>


    </div>
    <div>
      <a :href="'/list?catid=41'"><img src="@/assets/images/m.jpg" /></a>
    </div>
  </div>


  <div class="container">
    <div class="row" style="text-align: center"><img src="@/assets/images/icon28.png" height="115"/></div>
    <div class="row blog-left-content" v-for="(item, index) in testdata3" :key="index">

      <div class="blog-left-img col-lg-2 col-md-12 col-sm-12">
        <a :href="'/content?id='+item.id">
          <img :src="item.images" :alt="item.title">
        </a>
      </div>

      <div class="content col-lg-8 col-md-12 col-sm-12">
        <H3><a :href="'/content?id='+item.id">{{(item.title||'')}}</a></H3>
        <!--                <p>{{(item.description||'')}}</p>-->
<!--        <a :href="'/content?'+item.id" class="read-btn">Read More</a>-->
      </div>

      <div class="blog-right col-lg-2 col-md-12 col-sm-12">
        {{(item.publishTime||'').slice(0,11).replace(/-/g,'/')}}<br/>
<!--        {{(item.publishTime||'').slice(5,11).replaceAll('-','/')}}-->
      </div>


    </div>
    <div>
      <a :href="'/list?catid=42'"><img src="@/assets/images/m.jpg" /></a>
    </div>
  </div>


<!--	  <dl class="qywh_lay news_list_lay" id="xw2">-->
<!--	   <dt><img src="@/assets/images/icon28.png" /></dt>-->
<!--	   <dd>-->
<!--	     <div v-for="(item, index) in testdata3" :key="index">-->
<!--	       <img :src="item.images" class="pic" />-->
<!--	       <strong>{{item.title}}</strong>-->
<!--	       <span><em>{{item.publishTime}}</em>{{item.description}}</span>-->
<!--	       <router-link :to="{path:'/content',query:{id:item.id}}"><img src="@/assets/images/icon29.png" /></router-link>-->
<!--	     </div>-->
<!--	   </dd>-->
<!--      <div>-->
<!--        <a :href="'/list?catid=32'"><img src="@/assets/images/m.jpg" /></a>-->
<!--      </div>-->
<!--	  </dl>-->


	  <!-- qywh_lay -->
	 <fo_oter></fo_oter>
	<!-- footer -->
</div>
</template>
<style>
.blog-left-content {
  margin-bottom: 32px;
}
.blog-left-content .blog-left-img a img {
  max-width: 120px;
}
.blog-right {
  font-size: 30px;
  color: #999999;
  line-height: 36px;
  font-weight: bold;
  position: absolute;
  top: 14%;
  right: 23px;
  width: 80px;
}
.blog-left-content .blog-left-img {
  top: 10px;
  left: 17px;
}

.blog-left-content {
  margin-bottom: 40px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 15px rgba(0,0,0,.1);
  box-shadow: 0 0 15px rgba(0,0,0,.1);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  text-align: left;
}
.blog-left-content .content a{
  color: #ed730d;
}
@media (max-width: 768px) {
  .blog-right {
    top: 5%;
  }
}
</style>
<script>
import top_menu from '../components/menu.vue'
import fo_oter from '../components/footer.vue'
import "@/assets/js/jquery-1.9.1.min.js";
import "@/assets/bootstrap/bootstrap.min.js";
import {GETDATA1,GETDATA2,GETDATA3,GETDATA4} from '../apis/news.js'
import {GETLISTDATA} from "@/apis/api";
export default {
  name: 'news',
  components: {
    top_menu,fo_oter
  },
  data() {
    return {
		testdata:[],
		testdata1:[],
		testdata2:[],
		testdata3:[]
    };
  },
  mounted() {
  	this.onloading();
  	  // if (location.href.indexOf("#reloaded") == -1) {
  	  //    location.href = location.href + "#reloaded";
  	  //    location.reload();
  	  // };
  },
  methods: {
  	async onloading(){

      GETLISTDATA({catid:41,pageNum:1,pageSize:4}).then((res) => {
        if(res.code == 200){
          this.testdata = res.rows;
        }
      });

      GETLISTDATA({catid:42,pageNum:1,pageSize:4}).then((res) => {
        if(res.code == 200){
          this.testdata3 = res.rows;
        }
      });

  	   // this.testdata = await GETDATA1();
  	 //   this.testdata1 = await GETDATA2();
	   // this.testdata2 = await GETDATA3();
	   // this.testdata3 = await GETDATA4();
  	 //   this.testdata = this.testdata.rows;
  	 //   this.testdata1 = this.testdata1;
	   // this.testdata2 = this.testdata2;
	   // this.testdata3 = this.testdata3.rows;
	   // console.log(this.testdata3);
  	}
  }
}
</script>